import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//

const breakpoints = [640, 768, 1024, 1280, 1440];

const PageContainer = styled.div`
  position: relative;
  z-index: 250;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;

  ${breakpoints.map(
    (bp) => `@media(min-width: ${bp}px) { max-width: ${bp}px; }`
  )}
`;

const Container = ({ children, className, noFade }) => (
  <PageContainer data-sal={!noFade ? 'fade' : ''} className={className}>
    {children}
  </PageContainer>
);

export default Container;

//

Container.defaultProps = {
  className: null,
  noFade: false,
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noFade: PropTypes.bool,
};
