import { createGlobalStyle } from 'styled-components';

//

console.info(
  '%c\n\nWebsite built with 🟩 by\nhttps://www.modulareverything.com/\n\n',
  'color: green'
);

//

const GlobalStyles = createGlobalStyle`
  :root {    
    --development-by: 'https://modulareverything.com/';
    --development-desc: 'Blazing fast, secure, SEO-friendly React-based web applications.';

    --green--0: #303422;
    --green--1: #3B3F2B;
    --green--2: #32B700;
    --green--3: #EAF8E5;

    --red--0: #3D1B20;
    --red--1: #56272E;
    --red--2: #DA5755;
    --red--3: #FBEEEE;

    --blue--0: #181E36;
    --blue--1: #28324C;
    --blue--2: #008BD7;
    --blue--3: #E5F3FB;

    --orange--0: #44270C;
    --orange--1: #563113;
    --orange--2: #C87600;
    --orange--3: #F9F1E5;

    --earth--0: #51463A;
    --earth--1: #605449;
    --earth--2: #F4EADE;
    --earth--3: #FCF9F5;

    --grey--0: #282828;
    --grey--1: #939393;
    --grey--2: #D3D3D3;
    --grey--3: #F2F2F2;

    --white: #fff;
    --black: #060606;

    --transparent: transparent;
    --highlight: var(--grey--1);

    --font-sans: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --font-serif: 'Canela Deck', Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;

    --sal-duration: 750ms;

    --nav-transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1);

    --menuHeight: calc(5rem - 4rem);
    --menuGutter: 2rem;

    @media (min-width: 1024px) {

    /* --menuHeight: calc(12rem - 6rem); */
    --menuGutter: 3rem;
    }
  }

  html {
    /* overflow-x: hidden; */
    font-size: 11px;
  }

  body {
    /* overflow-x: hidden; */
    color: var(--grey--0);
    font-size: 2rem;
  }

  .swiper-container-horizontal>.swiper-scrollbar {
    right: 0;
    left: 0;
    width: 100%;
  }

  .swiper-scrollbar {
    padding: 0.5rem 0;
    background-color: transparent;

    .swiper-scrollbar-drag {
      z-index: 5;
      height: 0.5rem;
      border-radius: 0;
      background-color: var(--black);
      cursor: grab;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      top: 1rem;
      left: 0;
      width: 100%;
      height: 0.1rem;
      background-color: var(--black);
    }
  }
`;

export default GlobalStyles;
