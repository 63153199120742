import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SbEditable from 'storyblok-react';

const HubspotEmbed = ({content}) => {
  console.log("Hubspot:", content);
 
 return (
  <SbEditable content={content}>
    <script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src={`//js-na1.hs-scripts.com/${content.PortalID}.js`}
    ></script>
    </SbEditable>
)
};

export default HubspotEmbed;

HubspotEmbed.propTypes = {
  content: PropTypes.shape({
    PortalID: PropTypes.string,
  }),
};

HubspotEmbed.defaultProps = {
  content: {
    PortalID: null,
  },
};