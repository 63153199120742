import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import 'normalize.css';
import GlobalStyles from '../../styles/GlobalStyles';
import Typography from '../../styles/Typography';
import Header from '../Header';
import Footer from '../Footer';

//

const Layout = ({ children }) => {
  const HeaderRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    setHeaderHeight(HeaderRef.current.offsetHeight);

    window.addEventListener('resize', () => {
      if (HeaderRef.current) {
        setHeaderHeight(HeaderRef.current.offsetHeight);
      }
    });
  }, []);

  return (
    <>
      <GlobalStyles />
      <Typography />

      <Header HeaderRef={HeaderRef} />

      <Content padTop={headerHeight}>
        {children}

        <Footer />
      </Content>
    </>
  );
};

export default Layout;

const Content = styled.main`
  position: relative;
  top: ${(props) => props.padTop}px;
  overflow-x: hidden;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
