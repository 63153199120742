import React, { useState, useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import styled from "styled-components";
import { Link } from 'gatsby';
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap"; // Import Bootstrap ToggleButton components
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS


const CookieConsentBanner = () => {
  
  const [hubspotScriptInjected, setHubspotScriptInjected] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,       // Necessary cookies are always enabled
    functional: false,     // Functional cookies (e.g., Vimeo)
    analytics: false,      // Analytics cookies (e.g., Google Analytics)
    marketing: false,      // Marketing cookies (e.g., HubSpot, LinkedIn)
  });

  // Function to dynamically inject HubSpot tracking script
  const injectHubSpotScript = () => {
    if (!hubspotScriptInjected) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "hs-script-loader";
      script.async = true;
      script.defer = true;
      script.src = "//js.hs-scripts.com/5325021.js";
      document.body.appendChild(script);
      setHubspotScriptInjected(true); // Avoid reinjecting the script
      console.log("HubSpot script injected");
    }
  };

  // Update preferences based on existing cookies when the component mounts
  useEffect(() => {
    if (Cookies.get("gatsby-gdpr-hubspot") === "true") {
      injectHubSpotScript();

      // load HubSpot tracking script immediately after consent
      window._hsq = window._hsq || [];
      window._hsq.push(['trackPageView']);
      console.log("Hubspot is active");
    }

    // Show banner only if no cookies are set
    if (!Cookies.get("cookie-consent-banner")) {
      setShowBanner(true);
    }

  }, []);

  const handleAcceptAll = () => {
    setShowBanner(false);
    Cookies.set("cookie-consent-banner", "true", { path: "/" });

    // Set all preferences to true and update cookies accordingly
    const updatedPreferences = {
      necessary: true,
      functional: true,
      analytics: true,
      marketing: true,
    };

    setPreferences(updatedPreferences);

    Cookies.set("gatsby-gdpr-vimeo", "true", { path: "/" });
    Cookies.set("gatsby-gdpr-google-analytics", "true", { path: "/" });
    Cookies.set("gatsby-gdpr-google-tagmanager", "true", { path: "/" });
    Cookies.set("gatsby-gdpr-linked-in", "true", { path: "/" });
    Cookies.set("gatsby-gdpr-hubspot", "true", { path: "/" });

    console.log("User accepted all cookies:", updatedPreferences);

    // Reload the page after the user accepts the cookies
    window.location.reload();
  };

  const handleRejectAll = () => {
    setShowBanner(false);
    Cookies.set("cookie-consent-banner", "false", { path: "/" });

    // Set all preferences to true and update cookies accordingly
    const updatedPreferences = {
      necessary: true,
      functional: false,
      analytics: false,
      marketing: false,
    };

    // Set each cookie based on updated preferences
    Cookies.set("gatsby-gdpr-google-analytics", "false", { path: "/" });
    Cookies.set("gatsby-gdpr-google-tagmanager", "false", { path: "/" });
    Cookies.set("gatsby-gdpr-linked-in", "false", { path: "/" });
    Cookies.set("gatsby-gdpr-hubspot", "false", { path: "/" });
    Cookies.set("gatsby-gdpr-vimeo", "false", { path: "/" });
    
    console.log("User rejected all optional cookies.");
    
    // Reload the page after the user accepts the cookies
    window.location.reload();
  };

  const handleCustomize = () => {
    // Open preferences modal
    setShowPreferences(true);
  };

  const handleSavePreferences = () => {
    setShowBanner(false);
    Cookies.set("cookie-consent-banner", "true", { expires: 1 }); // Expires in 1 day

    // Inject scripts based on preferences
    if (preferences.necessary) {
    }

    if (preferences.analytics) {      
      Cookies.set("gatsby-gdpr-google-analytics", "true", { path: "/" });
      console.log("Google google analytics is active");

      Cookies.set("gatsby-gdpr-google-tagmanager", "true", { path: "/" });
      console.log("Google tagmanager is active");
    }

    if (preferences.marketing) {
      Cookies.set("gatsby-gdpr-hubspot", "true", { path: "/" });
      Cookies.set("gatsby-gdpr-linked-in", "true", { path: "/" });
      console.log("HubSpot and Linkedin is active");
    }

    if (preferences.functional) {
      Cookies.set("gatsby-gdpr-vimeo", "true", { path: "/" });
      console.log("Vimeo is active");
    }

    console.log("User saved custom preferences:", preferences);
    
    // Reload the page after the user accepts the cookies
    window.location.reload();
  };

  const togglePreference = (category) => {
    setPreferences({ ...preferences, [category]: !preferences[category] });
  };

return (
    <>
      {/* Cookie Consent Banner */}
      {showBanner && (
        <StyledBanner>
          <h4>We Use Cookies</h4>
          <p style={{ marginTop: "2.4rem" }} className="cookie-p">
             At Fulcrum GT, we're dedicated to empowering your firm journey. That's why we use cookies to enhance your experience on our site, optimize your interactions, offer social media features, and personalize our marketing efforts. We also use cookies to deliver content and ads tailored to your interests, including on third-party websites. You can adjust your settings by clicking 'Cookie Preferences. For more information, visit our <Link to="/privacy-policy">privacy policy</Link>.
          </p>
          <div className="button-cookie-container">
            <button className="reject-all cookie-button" onClick={handleRejectAll}>Reject All</button>
            <button className="accept-all cookie-button" onClick={handleAcceptAll}>Accept All</button>
            <button className="customize cookie-button" onClick={handleCustomize}>Cookie Preferences</button>
          </div>
        </StyledBanner>
      )}

      {/* Preferences Modal */}
      {showPreferences && (
        <PreferencesModal>
          <h2>Cookie Preferences</h2>
          <p className="cookie-p-modal">Choose which categories of cookies to allow:</p>
          <ul className="cookie-ul-modal">
            <li className="cookie-li-modal">
             <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="necessary"
                checked={preferences.necessary}
                disabled
              />
              <label className="form-check-label" htmlFor="necessary">
                Necessary Cookies (Always Enabled)
              </label>
             </div>
            </li>
            <li className="cookie-li-modal">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="analytics"
                  checked={preferences.analytics}
                  onChange={() => togglePreference("analytics")}
                />
                <label className="form-check-label" htmlFor="analytics">
                  Analytics Cookies (e.g., Google Analytic, Google Tagmanager)
                </label>
              </div>
            </li>
            <li className="cookie-li-modal">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="marketing"
                  checked={preferences.marketing}
                  onChange={() => togglePreference("marketing")}
                />
                <label className="form-check-label" htmlFor="marketing">
                  Marketing Cookies (e.g., HubSpot, LinkedIn)
                </label>
              </div>
            </li>
            <li className="cookie-li-modal">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="functional"
                  checked={preferences.functional}
                  onChange={() => togglePreference("functional")}
                />
                <label className="form-check-label" htmlFor="functional">
                  Functional Cookies (e.g., Vimeo)
                </label>
              </div>
            </li>
          </ul>
          <div className="buttons">
            <button className="buttons-modal" onClick={handleSavePreferences}>Save Preferences</button>
            <button className="buttons-modal" onClick={handleRejectAll}>Reject All</button>
          </div>
        </PreferencesModal>
      )}
    </>
  );
};

// Styled components for the banner and modal
const StyledBanner = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  color: white;
  text-align: center;
  z-index: 1000;

.cookie-p { 
font-size : 1.4rem;
line-height: 2rem;
word-spacing: 0px;
margin-right: 2.5vw;
}

  .button-cookie-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .cookie-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 13px;
    font-weight:450;
    color: black;
    margin: 5px;

    &.accept-all {
      background-color: #27ae60;
    }

    &.reject-all {
      background-color: #e74c3c;
    }

    &.customize {
      background-color: #f1c40f;
    }
  }
`;

// Styled components for the modal
const PreferencesModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 400px;

  h2 {
    margin-bottom: 20px;
  }

  .cookie-p-modal {
    margin-bottom: 20px;
  }

  .cookie-ul-modal {
    list-style: none;
    padding: 0;

    .cookie-li-modal {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      label {
        margin-left: 10px;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .buttons-modal {
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      font-size: 13px;
      background-color: #3498db;
      color: white;
    }
  }
`;


export default CookieConsentBanner;  
