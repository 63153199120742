import { createGlobalStyle } from 'styled-components';

import CanelaDeckLight from '../assets/fonts/CanelaDeck-Light.woff';
import CanelaDeckRegular from '../assets/fonts/CanelaDeck-Regular.woff';
import AvenirNextBold from '../assets/fonts/avenir-next-bold.woff';
import AvenirNextMedium from '../assets/fonts/avenir-next-medium.woff';

//

const Typography = createGlobalStyle`
  @font-face {
    font-family: 'Canela Deck';
    font-weight: 400;
    src: url(${CanelaDeckLight});
  }

  @font-face {
    font-family: 'Canela Deck';
    font-weight: 700;
    src: url(${CanelaDeckRegular});
  }

  @font-face {
    font-family: 'Avenir Next';
    font-weight: 700;
    src: url(${AvenirNextBold});
  }

  @font-face {
    font-family: 'Avenir Next';
    font-weight: 400;
    src: url(${AvenirNextMedium});
  }

  html {
    color: var(--black);
    font-family: var(--font-sans);
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: normal;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  mark, .mark {
    display: inline;
    margin: 0;
    padding: 0;
    background: var(--highlight);
    line-height: 1;
  }
`;

export default Typography;
