import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { BsArrowUpShort } from 'react-icons/bs';

//

const Button = ({ customTheme, content, callback, outline }) => {
  function handleClick() {
    // If it has a function, run the function
    if (callback) return callback();
    // Otherwise do nothing
    return false;
  }

  const theme = customTheme;

  const ButtonContent = () => (
    <ButtonWrap
      type="button"
      onClick={() => handleClick()}
      className={outline ? 'btn__type--outline' : 'btn__type--fill'}
      themeBackground={theme.background}
      themeDivider={theme.divider}
      themeText={theme.text}
      themeHighlight={theme.highlight}
      center={!content.arrow}
    >
      <span className="btn__label">{content.label}</span>
      {content.arrow && (
        <span className="btn__icon" aria-hidden="true">
          <BsArrowUpShort
            style={{ transform: `rotate(${content.arrow}deg)` }}
          />
        </span>
      )}
    </ButtonWrap>
  );

  if (typeof content.to === 'string') {
    return (
      <Link to={`/${content.to}`}>
        <ButtonContent />
      </Link>
    );
  }

  if (content.to && content.to.linktype === 'story') {
    const url = content.to.cached_url;
    return (
      <Link to={url === 'home' ? '/' : `/${url}`}>
        <ButtonContent />
      </Link>
    );
  }

  if (
    content.to &&
    (content.to.linktype === 'url' || content.to.linktype === 'asset')
  ) {
    const { url } = content.to;
    return (
      <a href={url} target="_blank" rel="noreferrer noopener">
        <ButtonContent />
      </a>
    );
  }

  return <ButtonContent />;
};

export default Button;

const ButtonWrap = styled.button`
  display: flex;
  align-content: center;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
  min-width: ${(props) => (props.center ? '9.6rem' : '20rem')};
  margin: 0;
  padding: ${(props) =>
    props.center ? '1rem 1.2rem' : '1rem 1.2rem 1rem 1.6rem'};
  transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
  border-width: 1px;
  border-style: solid;
  outline-color: var(--${(props) => props.themeDivider});
  background-color: transparent;
  font-size: 1.2rem;
  line-height: 1.8rem;
  cursor: pointer;

  a {
    text-decoration: none;
  }

  &.btn__type--outline {
    border: 1px solid var(--${(props) => props.themeHighlight});
    color: var(--${(props) => props.themeText});

    &:hover {
      background-color: var(--${(props) => props.themeHighlight});
      color: var(--${(props) => props.themeBackground});
    }
  }

  &.btn__type--fill {
    border: 1px solid var(--${(props) => props.themeText});
    background-color: var(--${(props) => props.themeText});
    color: var(--${(props) => props.themeBackground});

    &:hover {
      background-color: transparent;
      color: var(--${(props) => props.themeText});
    }
  }

  .btn-icon {
    margin-left: 2.4rem;
  }

  .btn__icon,
  .btn__icon svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

Button.propTypes = {
  customTheme: PropTypes.shape({
    background: PropTypes.string,
    divider: PropTypes.string,
    highlight: PropTypes.string,
    text: PropTypes.string,
  }),
  content: PropTypes.shape({
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    arrow: PropTypes.string,
    label: PropTypes.string,
  }),
  outline: PropTypes.bool,
  callback: PropTypes.func,
};

Button.defaultProps = {
  customTheme: {
    background: 'grey--0',
    divider: 'black',
    highlight: 'white',
    text: 'white',
  },
  content: {
    to: null,
    label: null,
    arrow: null,
  },
  outline: true,
  callback: null,
};
